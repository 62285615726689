.fade_type_top {
    animation-name: fade_type_top;
}

@keyframes fade_type_top {
    from {
        opacity: 0;
        transform: translate(0, 5px);
    }
    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}