/*noinspection CssUnresolvedCustomProperty*/
.content {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: calc(var(--vh, 1vh) * 75);
    padding: 50px 0;
}

@media screen and (max-width: 768px) {
    /*noinspection CssUnresolvedCustomProperty*/
    .content {
        min-height: calc(var(--vh, 1vh) * 65);
        padding: 25px 0;
    }
}