.fade_type_out {
    animation-name: fade_type_out;
}

@keyframes fade_type_out {
    from {
        opacity: 1;
        transform: translate(0, 0);
    }
    to {
        opacity: 0;
        transform: translate(0, 50px);
    }
}