.logo__container {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media screen and (max-device-width: 1100px) {
    .logo__container {
        align-items: center;
    }
}