.navbar {
    display: flex;
    gap: 10px 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-width: 893px;
}

@media screen and (max-device-width: 1100px) {
    .navbar {
        min-width: 100%;
    }
}