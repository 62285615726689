.header {
    min-height: 70px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    padding: 25px 0;
    transition: border-bottom-color .3s ease-in-out;
}

@media screen and (max-width: 1100px) {
    .header{
        display: flex;
        flex-direction: column;
        gap: 25px;
        min-width: 100%;
    }
}