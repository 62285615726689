.form__info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: var(--form-info-bg-color);
    border: 1px solid var(--form-info-border-color);
    border-radius: 4px;
    transition: all .5s ease-in-out;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
}