.form {
    min-width: 400px;
    max-width: 400px;
    margin: 0;
    padding: 45px 30px;
    box-sizing: border-box;
    box-shadow: var(--subnav-item-box-shadow);
    background-color: var(--subnav-item-background);
    transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out, height .3s ease-in-out;
    border-radius: 20px;
}

@media screen and (max-width: 768px) {
    .form {
        min-width: 100%;
        max-width: 100%;
    }
}