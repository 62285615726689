textarea {
    resize: none;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 16px;
    font-family: Helvetica, sans-serif;
    padding: 10px 15px;
    outline: none;
    width: 100%;
    background-color: transparent;
    box-sizing: border-box;
    color: var(--text-color);
    min-height: 62px;
    transition: border-color .3s ease-in-out;
}

textarea:focus:not(.form__input_invalid) {
    border-color: var(--border-color-focus);
}

textarea::placeholder {
    color: transparent;
    font-family: Helvetica, sans-serif;
    font-size: 12px;
    text-transform: lowercase;
}
textarea:focus::placeholder {
    color: var(--placeholder-color);
}

textarea:not(:placeholder-shown) + label,
textarea:focus + label {
    top: -21px;
    font-size: 14px;
    pointer-events: initial;
}