.form__button {
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    line-height: 24px;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 4px;
    width: auto;
    min-width: calc(50% - 10px);
    height: 68px;
    padding: 0;
    border: none;
    outline-offset: 2px;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    transition: background-color .3s ease-in-out, color .3s ease-in-out, border-color .3s ease-in-out;
    align-items: center;
}