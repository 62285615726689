.switcher {
    cursor: pointer;
    display: flex;
    min-width: 32px;
}

.switcher:last-of-type {
    margin-right: 0;
}



@media screen and (max-width: 1100px) {
    .switcher {
        grid-column: 3;
        margin-right: 0;
        align-self: end;
    }

    .switcher:last-of-type{
        grid-row: 1;
        align-self: start;
    }
}