.subnav__item {
    list-style-type: none;
    font-size: 32px;
    line-height: 37px;
    padding: 0;
    background-color: var(--subnav-item-background);
    border-radius: 20px;
    margin: 0 10px 10px 0;
    min-width: 240px;
    transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out;
    box-shadow: var(--subnav-item-box-shadow);
}

.subnav__item:hover {
    background-color: var(--subnav-item-background-hover);
    box-shadow: var(--subnav-item-box-shadow-hover);
}

.subnav__item:last-of-type {
    margin-right: 0;
}

@media screen and (max-width: 960px) {
    .subnav__item {
        min-width: inherit;
        width: 100%;
        margin-right: 0;
    }
}