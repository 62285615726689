input {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 16px;
    padding: 10px 15px;
    outline: none;
    width: 100%;
    background-color: transparent;
    margin: 0;
    box-sizing: border-box;
    color: var(--text-color);
    min-height: 62px;
    transition: border-color .3s ease-in-out, color .3s ease-in-out;
}
input:focus:not(.form__input_invalid) {
    border-color: var(--border-color-focus);
}

input::placeholder {
    color: transparent;
    font-size: 12px;
    text-transform: lowercase;
}
input:focus::placeholder {
    color: var(--placeholder-color);
}

input:not(:placeholder-shown) + label,
input:focus + label {
    top: -21px;
    font-size: 14px;
    pointer-events: initial;
}