.form__error {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    text-align: right;
    color: var(--error-color);
    margin: 0;
    padding: 5px 0 0;
    text-transform: lowercase;
}