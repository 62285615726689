:root {
    --background-color: #ffffff;
    --text-color: #000;
    --title-color: #626f84;
    --link-color: #4789eb;
    --link-color-active: #1a6be5;
    --border-color: #e2e5e9;
    --border-color-focus: #0949aa;
    --subnav-item-background: #ffffff;
    --error-color: #da0b20;
    --placeholder-color: #626f84;
    --subnav-item-box-shadow: rgba(0 0 0  / 20%) 0 2px 4px, rgb(0 0 0 / 4%) 0 -1px 2px;
    --subnav-item-box-shadow-hover: rgb(0 0 0 / 20%) 0 4px 8px, rgb(0 0 0 / 4%) 0 -1px 4px;
    --form-button-color: #0d69f2;
    --form-button-submit-color: #fff;
    --form-button-disabled-color: #c4cad4ff;
    --form-button-submit-disabled-color: #fff;
    --form-button-submit-disabled-bg-color: #c4cad4;
    --form-button-hover-color: #0a54c2;
    --form-button-reset-hover-bg-color: #f1f2f4;
    --form-info-bg-color: #f1f2f4;
    --form-info-border-color: #0d69f2;
    --form-info-text-color: #2b313b
}

.root {
    margin: 0;
    padding: 0;
    font-family: Helvetica, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    position: relative;
    user-select: none;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;
}



