.fade_type_in {
    animation-name: fade_type_in;
}

@keyframes fade_type_in {
    from {
        opacity: 0;
        transform: translate(0, -50px);
    }
    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}